import { lazy } from "react";
import { useRoutes } from "react-router-dom";

const EditPage = lazy(() => import("../containers/EditPage"));

const AppRouter = () => useRoutes([
    {
        path: "/",
        element: <EditPage />,
    }
])

export default AppRouter;